import React, { useState, useEffect } from 'react';
import s from './Expensive.module.css';
import Header from '../../components/Header/Header';
import ComplimentButton from '../../components/ComplimentButton/ComplimentButton';
import SmallCard from '../../components/SmallCard/SmallCard';
import expensive1 from '../../images/expensive-1.png';
import expensive2 from '../../images/expensive-2.png';
import expensive3 from '../../images/expensive-3.png';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

export default function Expensive() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: false,
    mode: 'free-snap',
    slides: {
      perView: 1.3,
      spacing: 10,
    },
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    localStorage.setItem('page', '/expensive');
  }, []);

  return (
    <div className={s.page}>
      <Header />
      <div className={s.container}>
        <h2 className={s.title}>
          Хотим рассказать о нескольких моментах, которые могут изменить ваше
          мнение
        </h2>

        <div ref={sliderRef} className="keen-slider">
          <div className="keen-slider__slide number-slide1">
            <SmallCard
              title="В коллекции табачных стиков Fiit 7 вкусов"
              img={expensive1}
              linkText="Узнать больше о Fiit"
              linkSrc="https://www.iqos.ru/devices/fiit/?flavour=flavour-3"
            />
          </div>
          <div className="keen-slider__slide number-slide2">
            <SmallCard
              title="Коллекция вкусов стиков HEETS доступна в наших фирменных магазинах Q STORE"
              img={expensive2}
              linkText="Найти магазин"
              linkSrc="https://www.iqos.ru/address/gde-kupit/?services=shop_official"
            />
          </div>
          {/* <div className="keen-slider__slide number-slide3">
            <SmallCard
              title="Посчитаем вместе разницу затрат на стики и сигареты с нашим калькулятором"
              img={expensive3}
              linkText="Рассчитать"
              linkSrc="https://cloud.iqos.ru/value-calculator/?thid=1"
            />
          </div> */}
        </div>
        {loaded && instanceRef.current && (
          <div className={s.dots}>
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={`${s.dot} ${currentSlide === idx ? s.active : ''}`}
                ></button>
              );
            })}
          </div>
        )}

        <ComplimentButton />
        <p className={s.footnote}>
          *Цена в фирменных магазинах Q STORE и в ряде магазинов-партнёров Q
          PARTNER. Цены могут меняться, уточняйте в конкретном выбранном вами
          магазине.
        </p>
      </div>
    </div>
  );
}
