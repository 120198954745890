import React from 'react';
import s from './FirstQuestion.module.css';
import Header from '../../components/Header/Header';
import AnswersList from '../../components/AnswerList/AnswersList';
import ellipse from '../../images/ellipse.svg';

export default function FirstQuestion() {
  const answers = [
    { text: 'Я не могу полностью перейти на IQOS / lil SOLID', link: '/why' },
    { text: 'Мне нравится иногда курить сигареты', link: '/why' },
    { text: 'Я использую только IQOS/lil SOLID', link: '/great-choice' },
  ];

  return (
    <div className={s.page}>
      <img src={ellipse} alt="" className={s.bg} />
      <Header />
      <div className={s.container}>
        <div className={s.content}>
          <h2 className={s.title}>Расскажите про свой опыт</h2>
          <AnswersList answers={answers} />
        </div>
      </div>
    </div>
  );
}
