import React, { useState, useEffect } from 'react';
import s from './DeviceProblems.module.css';
import Header from '../../components/Header/Header';
import ComplimentButton from '../../components/ComplimentButton/ComplimentButton';
import SmallCard from '../../components/SmallCard/SmallCard';
import img1 from '../../images/problems-1.png';
import img2 from '../../images/problems-2.png';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

export default function DeviceProblems() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: false,
    mode: 'free-snap',
    slides: {
      perView: 1.3,
      spacing: 10,
    },
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    localStorage.setItem('page', '/device-problems');
  }, []);

  return (
    <div className={s.page}>
      <Header />
      <div className={s.container}>
        <h2 className={s.title}>
          Давайте вместе посмотрим, что случилось, и мы поможем в решении вашей
          проблемы
        </h2>

        <div ref={sliderRef} className="keen-slider">
          <div className="keen-slider__slide number-slide1">
            <SmallCard
              title="Вы можете пройти онлайн - диагностику или оффлайн в фирменных магазинах Q STORE"
              img={img1}
              linkText="Найти магазин"
              linkSrc="https://www.iqos.ru/address/gde-kupit/?services=service_replace"
              secondLinkText="Онлайн диагностика"
              secondLinkSrc="https://www.iqos.ru/address/gde-kupit/?services=service_replace%2Cshop_official"
            />
          </div>
          <div className="keen-slider__slide number-slide2">
            <SmallCard
              title="Если устройство потеряно, вы можете приобрести новое устройство, воспользовав-шись сервисом КЛИК"
              img={img2}
              linkText="Забронировать"
              linkSrc="https://www.iqos.ru/shop/current-devices/"
            />
          </div>
        </div>
        {loaded && instanceRef.current && (
          <div className={s.dots}>
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={`${s.dot} ${currentSlide === idx ? s.active : ''}`}
                ></button>
              );
            })}
          </div>
        )}

        <ComplimentButton />
      </div>
    </div>
  );
}
