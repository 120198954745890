import React from 'react';
import s from './MiniCard.module.css';
import { Link } from 'react-router-dom';
import arrow from '../../images/light-arrow.svg';
import { endData } from '../../utils/api';

export default function MiniCard({
  title,
  text,
  img,
  linkSrc,
  dark,
  onClicker,
}) {
  return (
    // <div className={`${s.card} keen-slider__slide number-slide${number}`} style={{ minWidth: '70%' }}>
    <div className={s.card}>
      <h3 className={`${s.title} ${dark ? s.dark : ''}`}>{title}</h3>
      <p className={s.text}>{text}</p>
      <div className={s.priceContainer}></div>

      <img src={img} alt="" className={s.img} />
      {linkSrc && (
        <div
          className={s.button}
          onClick={async () => {
            await endData();
            await onClicker();
            // window.location.href = 'https://t.me/addstickers/iqos_summer';
          }}
        >
          Выбрать <img src={arrow} alt="" />
        </div>
      )}
    </div>
  );
}
